import apiClient from '@/services/api'

export default {
  total_orders: {
    show(params) {
      return apiClient.get('api/dashboard/widgets/total_orders', { params })
    },
    pdf: {
      create(params) {
        return apiClient.post(
          'api/dashboard/widgets/total_orders/pdf_report',
          params,
          {
            responseType: 'blob',
            headers: {
              Accept: 'application/pdf',
            },
          }
        )
      },
    },
    statistics: {
      show(params) {
        return apiClient.get('api/dashboard/widgets/total_orders/statistics', {
          params,
        })
      },
      pdf: {
        create(params) {
          return apiClient.post(
            'api/dashboard/widgets/total_orders/statistics/pdf_report',
            params,
            {
              responseType: 'blob',
              headers: {
                Accept: 'application/pdf',
              },
            }
          )
        },
      },
    },
  },
  expiring_prices: {
    show(params) {
      return apiClient.get('api/dashboard/widgets/expiring_prices', { params })
    },
  },
  deliveries: {
    show(params) {
      return apiClient.get('api/dashboard/widgets/deliveries', { params })
    },
  },
  open_payments: {
    show(params) {
      return apiClient.get('api/dashboard/widgets/open_payments', { params })
    },
  },
  procurements: {
    show(params) {
      return apiClient.get('api/dashboard/widgets/procurement', { params })
    },
  },
  sustainabilities: {
    show(params) {
      return apiClient.get('api/dashboard/widgets/sustainability', { params })
    },
  },
}
