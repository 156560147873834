import itemsApi from '@/services/api/items'
import itemReplacementsApi from '@/services/api/item-replacements'
import { uniqBy } from 'lodash'
import userPreferencesApi from '@/services/api/user-preferences'
import { viewTypeToIconName } from '@/services/helpers'

export const namespaced = true

export const state = {
  count: 0,
  total_count: 0,
  current_page: 0,
  pages: 0,
  per_page: 0,
  q: null,
  list: [],
  view_type: 'full-view-icon_24px',
  infiniteId: +new Date(),
}

export const mutations = {
  ADD_ITEMS_DATA(state, { items, view_type, ...paginationMeta }) {
    const enrichedItems = items.map((item) => {
      return { ...item, replacements: items }
    })
    state.list = uniqBy([...state.list, ...enrichedItems], 'id')
    state.view_type = viewTypeToIconName(view_type)
    state.current_page += 1
    state.total_count = paginationMeta.total_count
  },
  RESET_LOADED_ITEMS(state) {
    state.list = []
    state.current_page = 0
    state.infiniteId += 1
  },
  SET_VIEW_TYPE(state, value) {
    state.view_type = value
  },
  UPDATE_ITEM(state, { item }) {
    state.list = state.list.map((oldItem) => {
      if (oldItem.variant_id !== item.variant_id) return oldItem

      return { ...oldItem, ...item }
    })
  },
}

export const actions = {
  filterItems({ commit }) {
    commit('RESET_LOADED_ITEMS')
  },
  async loadItems({ commit, state }, { infiniteLoaderContext, id }) {
    const { current_page, q } = state

    try {
      const response = await itemReplacementsApi.index(id, {
        page: current_page + 1,
        q,
      })

      if (response.data.pages <= response.data.current_page) {
        infiniteLoaderContext.complete()
      } else {
        infiniteLoaderContext.loaded()
      }

      commit('ADD_ITEMS_DATA', response.data)
    } catch (error) {
      infiniteLoaderContext.error()
    }
  },
  async updateViewType({ commit }, value) {
    const mappings = {
      'full-view-icon_24px': 'items_full',
      'card-view-icon_24px': 'items_card',
      actions_list_view: 'items_list',
    }

    await userPreferencesApi.update({ items_view_type: mappings[value] })

    commit('SET_VIEW_TYPE', value)
  },
  async verifyItemUnits({ commit }, { variantId }) {
    const response = await itemsApi.verify_units.create(variantId)

    commit('UPDATE_ITEM', response.data)

    return response.data
  },
}

export const getters = {
  lineItemFromPrice: (_state, _getters, rootState) => (item) => {
    const { currentCart } = rootState.currentUser.currentUser

    return currentCart.line_items.find(
      (lineItem) =>
        lineItem.supplier_tenant_id === item.supplier_tenant_id &&
        lineItem.variant_id === item.variant_id
    )
  },
  qtyInCart: (_state, getters) => (item) => {
    const lineItem = getters.lineItemFromPrice(item)

    return lineItem ? lineItem.quantity : 0
  },
}
