import costCentersApi from '@/services/api/cost-centers'
import dayjs from 'dayjs'
import groupBy from 'lodash/groupBy'
import holidaysApi from '@/services/api/holidays'
import jobsApi from '@/services/api/jobs'
import supplierTenants from '@/services/api/supplier-tenants'
import { uniqBy, isEmpty } from 'lodash'

export const namespaced = true

const hours = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
]

const minutes = ['00', '15', '30', '45']

export const state = {
  count: 0,
  total_count: 0,
  fetching_holidays: false,
  holidays_of_the_year: [],
  cost_center_options: [],
  current_page: 0,
  global_count: null,
  supplier_loading: false,
  show_all_suppliers: null,
  pages: 0,
  per_page: 0,
  q: null,
  sort: {
    value: { name: 'name_asc', attr: 'suppliers.name' },
    options: [
      { name: 'name_asc', attr: 'suppliers.name' },
      { name: 'name_desc', attr: '-suppliers.name' },

      { name: 'valid_to_asc', attr: 'valid_to' },
      { name: 'valid_to_desc', attr: '-valid_to' },
    ],
  },
  list: [],
  csv: {
    attachmentId: null,
    loading: false,
    currentStep: 1,
    currentJobId: null,
    steps: [{ number: 1 }, { number: 2 }, { number: 3 }, { number: 4 }],
    preselectedCostCenters: [],
    existingItemsCount: 0,
    importJobData: {
      at: '0',
    },
    importType: 'modify',
    importFileData: {},
    isCsvValidating: false,
    withSupplierSelect: false,
    isUploadingCsv: false,
    isСsvDone: false,
    draftItems: [],
    allergens: {
      value: [],
      options: [],
    },
    productGroups: {
      value: null,
      options: [],
    },
    packaging_types: {
      value: null,
      options: [],
    },
    base_unit_types: {
      value: null,
      options: [],
    },
    additives: {
      value: [],
      options: [],
    },
    validationJobData: {
      at: '0',
    },
    import_mapping: {},
  },
  infiniteId: +new Date(),
  selected_supplier_tenant: {},
  delivery_form: {
    shipping_costs: 0,
    order_until_enabled: false,
    delivery_company: {
      value: null,
      options: [],
    },
    delivery_cost_center: {
      value: null,
      options: [],
    },
    order_until_time: [
      {
        id: 1,
        value: '03',
        options: hours,
      },
      {
        id: 2,
        value: '15',
        options: minutes,
      },
    ],
    delivery_time_enabled: false,
    delivery_time_days: [
      {
        id: 1,
        value: '3',
        options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      },
    ],
    delivery_days_enabled: false,
    delivery_days: {
      selected_day: null,
      values: [],
      options: [
        {
          day: 'monday',
          from: [
            {
              id: 'hours',
              value: '00',
              options: hours,
            },
            {
              id: 'minutes',
              value: '00',
              options: minutes,
            },
          ],
          to: [
            {
              id: 'hours',
              value: '00',
              options: hours,
            },
            {
              id: 'minutes',
              value: '00',
              options: minutes,
            },
          ],
          all_day: true,
        },
        {
          day: 'tuesday',
          from: [
            {
              id: 'hours',
              value: '00',
              options: hours,
            },
            {
              id: 'minutes',
              value: '00',
              options: minutes,
            },
          ],
          to: [
            {
              id: 'hours',
              value: '00',
              options: hours,
            },
            {
              id: 'minutes',
              value: '00',
              options: minutes,
            },
          ],
          all_day: true,
        },
        {
          day: 'wednesday',
          from: [
            {
              id: 'hours',
              value: '00',
              options: hours,
            },
            {
              id: 'minutes',
              value: '00',
              options: minutes,
            },
          ],
          to: [
            {
              id: 'hours',
              value: '00',
              options: hours,
            },
            {
              id: 'minutes',
              value: '00',
              options: minutes,
            },
          ],
          all_day: true,
        },
        {
          day: 'thursday',
          from: [
            {
              id: 'hours',
              value: '00',
              options: hours,
            },
            {
              id: 'minutes',
              value: '00',
              options: minutes,
            },
          ],
          to: [
            {
              id: 'hours',
              value: '00',
              options: hours,
            },
            {
              id: 'minutes',
              value: '00',
              options: minutes,
            },
          ],
          all_day: true,
        },
        {
          day: 'friday',
          from: [
            {
              id: 'hours',
              value: '00',
              options: hours,
            },
            {
              id: 'minutes',
              value: '00',
              options: minutes,
            },
          ],
          to: [
            {
              id: 'hours',
              value: '00',
              options: hours,
            },
            {
              id: 'minutes',
              value: '00',
              options: minutes,
            },
          ],
          all_day: true,
        },
        {
          day: 'saturday',
          from: [
            {
              id: 'hours',
              value: '00',
              options: hours,
            },
            {
              id: 'minutes',
              value: '00',
              options: minutes,
            },
          ],
          to: [
            {
              id: 'hours',
              value: '00',
              options: hours,
            },
            {
              id: 'minutes',
              value: '00',
              options: minutes,
            },
          ],
          all_day: true,
        },
        {
          day: 'sunday',
          from: [
            {
              id: 'hours',
              value: '00',
              options: hours,
            },
            {
              id: 'minutes',
              value: '00',
              options: minutes,
            },
          ],
          to: [
            {
              id: 'hours',
              value: '00',
              options: hours,
            },
            {
              id: 'minutes',
              value: '00',
              options: minutes,
            },
          ],
          all_day: true,
        },
      ],
    },
    deliver_on_holidays: false,
    customer_number: null,
    supplier_number: null,
    sunday_flex_price_on_holidays: false,
  },
  ftp_setting_form: {
    enabled: false,
    hostname: null,
    username: null,
    password: null,
    remote_path: null,
  },
  isBlankState: null,
}

export const mutations = {
  SET_COST_CENTERS_OPTIONS(state, costCenters) {
    state.cost_center_options = costCenters
  },
  SET_SUPPLIERS_DATA(state, { suppliers, ...paginationMeta }) {
    state.list = suppliers
    state.total_count = paginationMeta.total_count
  },
  ADD_SUPPLIERS_DATA(
    state,
    { data: { suppliers, ...paginationMeta }, noFilters }
  ) {
    state.list = [...state.list, ...suppliers]
    state.current_page += 1
    state.total_count = paginationMeta.total_count
    state.isBlankState = noFilters && paginationMeta.total_count === 0
  },
  ADD_SUPPLIER_DATA(state, supplier) {
    state.list.unshift(supplier)
  },
  ADD_SUPPLIER_TENANT_ADDRESSES(state, address) {
    state.selected_supplier_tenant.addresses =
      state.selected_supplier_tenant.addresses.filter((itm) => {
        return itm.id !== address.id
      })

    state.selected_supplier_tenant.addresses = [
      address,
      ...state.selected_supplier_tenant.addresses,
    ]
  },
  REMOVE_SUPPLIER_TENANT_ADDRESSES(state, address) {
    state.selected_supplier_tenant.addresses =
      state.selected_supplier_tenant.addresses.filter((itm) => {
        return itm.id !== address.id
      })
  },
  ADD_SUPPLIER_TENANT_CONTACTS(state, contact) {
    state.selected_supplier_tenant.contacts =
      state.selected_supplier_tenant.contacts.filter((itm) => {
        return itm.id !== contact.id
      })

    state.selected_supplier_tenant.contacts = [
      contact,
      ...state.selected_supplier_tenant.contacts,
    ]
  },
  REMOVE_SUPPLIER_TENANT_CONTACTS(state, contact) {
    state.selected_supplier_tenant.contacts =
      state.selected_supplier_tenant.contacts.filter((itm) => {
        return itm.id !== contact.id
      })
  },
  REMOVE_SUPPLIER_DATA(state, id) {
    state.list = state.list.filter((supplier) => supplier.id !== id)
  },
  RESET_CSV_STEPPER(state) {
    state.csv.currentStep = 1
    state.csv.draftItems = []
  },
  RESET_DELIVERY_FORM(state) {
    state.delivery_form.shipping_costs = 0
    state.delivery_form.order_until_enabled = false
    state.delivery_form.order_until_time.forEach((item) => (item.value = '00'))
    state.delivery_form.delivery_time_enabled = false
    state.delivery_form.delivery_time_days.forEach((item) => (item.value = '1'))
    state.delivery_form.delivery_days_enabled = false
    state.delivery_form.delivery_days.selected_day = null
    state.delivery_form.delivery_days.values = []
    state.delivery_form.delivery_company = { value: null, options: [] }
    state.delivery_form.delivery_cost_center = { value: null, options: [] }
    state.delivery_form.customer_number = null
    state.delivery_form.supplier_number = null
  },
  RESET_LOADED_SUPPLIERS(state) {
    state.list = []
    state.current_page = 0
    state.infiniteId += 1
  },
  RESET_PROGRESS(state) {
    state.csv.importJobData.at = '0'
    state.csv.validationJobData.at = '0'
  },
  REPLACE_SUPPLIER_DATA(state, supplier) {
    state.list = state.list.map((storedSupplier) =>
      storedSupplier.id === supplier.id ? supplier : storedSupplier
    )
  },
  SELECT_DELIVERY_DAY(state, day) {
    state.delivery_form.delivery_days.selected_day = day
  },
  SET_DELIVERY_TIME_DAYS(state, { id, value }) {
    state.delivery_form.delivery_time_days.forEach((item) => {
      if (item.id === id) {
        item.value = value
      }
    })
  },
  SET_ORDER_UNTIL_TIME(state, { id, value }) {
    state.delivery_form.order_until_time.forEach((item) => {
      if (item.id === id) {
        item.value = value
      }
    })
  },
  SET_CSV_CURRENT_STEP(state, number) {
    state.csv.currentStep = number
  },
  SET_CSV_JOB_DATA(state, { job_id, attachment }) {
    state.csv.currentJobId = job_id
    state.csv.attachmentId = attachment.id
  },
  SET_CSV_DRAFT_ITEMS(
    state,
    {
      draft_items,
      product_group_options,
      packaging_type_options,
      base_unit_type_options,
      allergen_options,
      additive_options,
      import_mapping,
    }
  ) {
    state.csv.draftItems = draft_items.map((item) => {
      return {
        ...item,
        isManuallyFixed: false,
        new_allergens: [],
        new_additives: [],
        new_product_group: null,
        new_unit: null,
      }
    })
    state.csv.productGroups.options = product_group_options
    state.csv.packaging_types.options = packaging_type_options
    state.csv.base_unit_types.options = base_unit_type_options
    state.csv.allergens.options = allergen_options
    state.csv.additives.options = additive_options
    state.csv.import_mapping = import_mapping
  },
  SET_CSV_IMPORT_LOADING(state, value) {
    state.csv.loading = value
  },
  SET_CSV_LOADING(state, { type, value }) {
    state.csv[type] = value
  },
  SET_VALIDATION_JOB_DATA(state, jobData) {
    state.csv.validationJobData = jobData
  },
  SET_CSV_IMPORT_JOB(state, jobData) {
    state.csv.importJobData = jobData
  },
  SET_CSV_ATTACHMENT_LOADING(state, value) {
    state.csv.loading = value
  },
  SET_HOLIDAYS(state, holidays) {
    state.holidays_of_the_year = holidays
  },
  SET_SELECTED_SUPPLIER_TENANT(state, data) {
    state.selected_supplier_tenant = data
    state.delivery_form.delivery_cost_center.options = data.cost_centers.map(
      (cc) => ({ ...cc, cost_center_id: cc.id })
    )
  },
  SYNC_DELIVERY_FORM(state) {
    const deliveryForm = state.delivery_form
    const currentSupplierTenant = state.selected_supplier_tenant

    const defaultDeliveryCostCenter =
      currentSupplierTenant.current_supplier_tenant_customer_cost_center

    const defaultValue = defaultDeliveryCostCenter
      ? {
          name: defaultDeliveryCostCenter.cost_center_name,
          cost_center_id: defaultDeliveryCostCenter.cost_center_id,
          company_name: defaultDeliveryCostCenter.company_name,
        }
      : null

    deliveryForm.delivery_cost_center = {
      value: deliveryForm.delivery_cost_center.value || defaultValue,
      options: currentSupplierTenant.delivery_cost_centers.map((dcs) => {
        return {
          name: dcs.cost_center_name,
          cost_center_id: dcs.cost_center_id,
          company_name: dcs.company_name,
        }
      }),
    }

    deliveryForm.delivery_company = {
      value: {
        name: deliveryForm.delivery_cost_center?.value?.company_name || null,
      },
      options: [],
    }

    deliveryForm.deliver_on_holidays = currentSupplierTenant.deliver_on_holidays
    deliveryForm.sunday_flex_price_on_holidays =
      currentSupplierTenant.sunday_flex_price_on_holidays

    const currentCustomerCostCenter =
      currentSupplierTenant.delivery_cost_centers.find((dcc) => {
        return (
          dcc.cost_center_id ===
          deliveryForm.delivery_cost_center.value?.cost_center_id
        )
      })

    if (!currentCustomerCostCenter) return

    deliveryForm.shipping_costs = currentCustomerCostCenter.shipping_costs

    deliveryForm.order_until_enabled =
      currentCustomerCostCenter.order_until_enabled

    deliveryForm.delivery_time_enabled =
      currentCustomerCostCenter.delivery_time_enabled

    deliveryForm.delivery_days_enabled =
      currentCustomerCostCenter.delivery_days_enabled

    deliveryForm.customer_number = currentCustomerCostCenter.customer_number

    deliveryForm.supplier_number = currentCustomerCostCenter.supplier_number

    deliveryForm.delivery_time_days.forEach((item) => {
      item.value = currentCustomerCostCenter.delivery_time_days
    })

    deliveryForm.order_until_time.forEach((item, i) => {
      if (currentCustomerCostCenter.order_until_time) {
        item.value = currentCustomerCostCenter.order_until_time.split(':')[i]
      }
    })

    currentCustomerCostCenter.delivery_days.forEach((item) => {
      deliveryForm.delivery_days.options.forEach((dayItem) => {
        if (dayItem.day === item.day) {
          dayItem.from = item.from
          dayItem.to = item.to
          dayItem.all_day = item.all_day
        }
      })

      deliveryForm.delivery_days.values = uniqBy(
        [...deliveryForm.delivery_days.values, item],
        'day'
      )
    })
  },
  SYNC_FTP_SETTING_FORM(state) {
    state.ftp_setting_form.enabled =
      state.selected_supplier_tenant.ftp_setting.enabled

    state.ftp_setting_form.hostname =
      state.selected_supplier_tenant.ftp_setting.hostname

    state.ftp_setting_form.username =
      state.selected_supplier_tenant.ftp_setting.username

    state.ftp_setting_form.password =
      state.selected_supplier_tenant.ftp_setting.password

    state.ftp_setting_form.remote_path =
      state.selected_supplier_tenant.ftp_setting.remote_path
  },
  UPDATE_DRAFT_ITEM_VALIDITY(state, item) {
    const draftItem = state.csv.draftItems.find(
      (draftItem) => draftItem.id === item.id
    )

    draftItem.isManuallyFixed = isEmpty(item.errors_data)
  },
  SWITCH_CSV_OVERWRITE(state, value) {
    state.csv.overwrite = value
  },
  SET_DELIVERY_COST_CENTER(state, option) {
    state.delivery_form.delivery_cost_center.value = option
    state.delivery_form.delivery_company = {
      value: {
        name:
          state.delivery_form.delivery_cost_center?.value?.company_name || null,
      },
      options: [],
    }

    let deliveryCostCenter = option

    if (state.selected_supplier_tenant.delivery_cost_centers) {
      deliveryCostCenter =
        state.selected_supplier_tenant.delivery_cost_centers.find(
          (dcc) => dcc.cost_center_id === option.cost_center_id
        )
    }

    if (!deliveryCostCenter) return

    state.delivery_form = {
      ...state.delivery_form,
      shipping_costs: deliveryCostCenter.shipping_costs,
      order_until_enabled: deliveryCostCenter.order_until_enabled,
      delivery_time_enabled: deliveryCostCenter.delivery_time_enabled,
      delivery_days_enabled: deliveryCostCenter.delivery_days_enabled,
      customer_number: deliveryCostCenter.customer_number,
      supplier_number: deliveryCostCenter.supplier_number,
    }

    state.delivery_form.delivery_time_days.forEach((item) => {
      item.value = deliveryCostCenter.delivery_time_days
    })

    state.delivery_form.order_until_time.forEach((item, i) => {
      if (deliveryCostCenter.order_until_time) {
        item.value = deliveryCostCenter.order_until_time.split(':')[i]
      }
    })

    deliveryCostCenter.delivery_days.forEach((item) => {
      state.delivery_form.delivery_days.options.forEach((dayItem) => {
        if (dayItem.day === item.day) {
          dayItem.from = item.from
          dayItem.to = item.to
          dayItem.all_day = item.all_day
        }
      })

      state.delivery_form.delivery_days.values = uniqBy(
        [...state.delivery_form.delivery_days.values, item],
        'day'
      )
    })
  },
  SET_DELIVERY_COST_CENTER_OPTIONS(state, costCenters) {
    state.delivery_form.delivery_cost_center.options = costCenters.map(
      (cc) => ({ ...cc, cost_center_id: cc.id })
    )
  },
  SET_IMPORT_FILE_DATA(state, data) {
    state.csv.importFileData = data
  },
  SET_IMPORT_TYPE(state, type) {
    state.csv.importType = type
  },
  SET_ITEM_VALIDATING(state, value) {
    state.csv.validating = value
  },
  SET_SUPPLIER_LOADING(state, value) {
    state.supplier_loading = value
  },
}

export const actions = {
  resetDeliveryForm({ commit }) {
    commit('RESET_DELIVERY_FORM')
  },
  resetProgress({ commit }) {
    commit('RESET_PROGRESS')
  },
  async compareAttachment(_ctx, { supplierId, formData }) {
    return await supplierTenants.compared_attachment.update(
      supplierId,
      formData
    )
  },
  async createSupplierTenant({ commit }, params) {
    const requestParams = {
      ...params,
      ...{
        addresses: params.addresses.map((address) => {
          return {
            ...address,
            country: address.country.iso,
            state: address.state ? address.state.abbr : null,
          }
        }),
      },
    }
    const response = await supplierTenants.create(requestParams)

    commit('ADD_SUPPLIER_DATA', response.data)
  },
  async createSupplierTenantCostCenterPrices(
    _ctx,
    { supplierTenantId, ...params }
  ) {
    await supplierTenants.cost_center_prices.create(supplierTenantId, params)
  },
  csvStepTo({ commit }, number) {
    commit('SET_CSV_CURRENT_STEP', number)
  },
  async deleteSupplier({ commit }, id) {
    const { data } = await supplierTenants.delete(id)

    commit('REPLACE_SUPPLIER_DATA', data)

    if (!data.has_orders) {
      commit('REMOVE_SUPPLIER_DATA', id)
    }
  },
  filterSuppliers({ commit }) {
    commit('RESET_LOADED_SUPPLIERS')
  },
  async getAttachment({ commit }, id) {
    commit('SET_CSV_ATTACHMENT_LOADING', true)

    const response = await supplierTenants.items_csv_attachments.show(id)

    commit('SET_CSV_ATTACHMENT_LOADING', false)
    commit('SET_CSV_DRAFT_ITEMS', response.data)
    commit('SET_CSV_LOADING', { type: 'isCsvValidating', value: false })
  },
  async getCostCenterOptions({ commit }) {
    const response = await costCentersApi.index({ all: true })

    commit('SET_COST_CENTERS_OPTIONS', response.data.cost_centers)
  },
  async getDeliveryCostCenterOptions({ commit }) {
    const response = await costCentersApi.index({ all: true })

    commit('SET_DELIVERY_COST_CENTER_OPTIONS', response.data.cost_centers)
  },
  async getHolidays({ commit }) {
    const response = await holidaysApi.index()

    commit('SET_HOLIDAYS', response.data.holidays)
  },
  async getJobData({ commit }, id) {
    const response = await jobsApi.show(id)

    commit('SET_VALIDATION_JOB_DATA', response.data)

    if (response.data.status === 'complete') {
      commit('SET_ITEM_VALIDATING', false)
    }
  },
  async getImportJobData({ commit }, id) {
    const response = await jobsApi.show(id)

    commit('SET_CSV_IMPORT_JOB', response.data)
  },
  async getSupplierTenantOptions() {
    return await supplierTenants.index({ all: true })
  },
  async importValidItems({ commit }, { supplierId, attachmentId }) {
    commit('SET_CSV_IMPORT_LOADING', true)

    const response = await supplierTenants.item_list.create(supplierId, {
      attachment_id: attachmentId,
    })

    commit('SET_CSV_JOB_DATA', {
      job_id: response.data.job_id,
      attachment: { id: attachmentId },
    })

    commit('SET_CSV_IMPORT_LOADING', false)
  },
  async loadSuppliers({ commit, state, getters }, infiniteLoaderContext) {
    const {
      current_page,
      q,
      sort: { value },
      show_all_suppliers,
    } = state
    const startInfiniteId = state.infiniteId

    try {
      const response = await supplierTenants.index({
        page: current_page + 1,
        q,
        sort: value && value.attr,
        show_all_suppliers: show_all_suppliers,
      })

      const endInfiniteId = state.infiniteId

      if (startInfiniteId !== endInfiniteId) return

      if (response.data.pages <= current_page) {
        infiniteLoaderContext.complete()
      } else {
        infiniteLoaderContext.loaded()
      }

      commit('ADD_SUPPLIERS_DATA', {
        data: response.data,
        noFilters: isEmpty(q) && getters.showAllSuppliers === true,
      })
    } catch (error) {
      infiniteLoaderContext.error()
    }
  },
  resetCsvStepper({ commit }) {
    commit('RESET_CSV_STEPPER')
  },
  selectDeliveryDay({ commit }, day) {
    commit('SELECT_DELIVERY_DAY', day)
  },
  selectSupplierTenant({ commit }, data) {
    commit('SET_SELECTED_SUPPLIER_TENANT', data)
  },
  setImportType({ commit }, type) {
    commit('SET_IMPORT_TYPE', type)
  },
  setImportFileData({ commit }, data) {
    commit('SET_IMPORT_FILE_DATA', data)
  },
  setCsvLoading({ commit }, { type, value }) {
    commit('SET_CSV_LOADING', { type, value })
  },
  setItemValidating({ commit }, value) {
    commit('SET_ITEM_VALIDATING', value)
  },
  setSupplierLoading({ commit }, value) {
    commit('SET_SUPPLIER_LOADING', value)
  },
  setDeliveryTimeDays({ commit }, { id, value }) {
    commit('SET_DELIVERY_TIME_DAYS', { id, value })
  },
  setOrderUntilTime({ commit }, { id, value }) {
    commit('SET_ORDER_UNTIL_TIME', { id, value })
  },
  setDeliveryCostCenter({ commit }, option) {
    commit('SET_DELIVERY_COST_CENTER', option)
  },
  setDeliveryCostCenterOptions({ commit }, options) {
    commit('SET_DELIVERY_COST_CENTER_OPTIONS', options)
  },
  syncDeliveryForm({ commit }) {
    commit('SYNC_DELIVERY_FORM')
  },
  syncFtpSettingForm({ commit }) {
    commit('SYNC_FTP_SETTING_FORM')
  },
  async switchCsvOverwrite({ commit }, value) {
    commit('SWITCH_CSV_OVERWRITE', value)
  },
  async uploadCsv({ commit }, { supplierId, formData }) {
    const response = await supplierTenants.items_csv_attachments.create(
      supplierId,
      formData
    )

    commit('SET_CSV_JOB_DATA', response.data)
  },
  async verifyCsv({ commit }, { attachmentId, data }) {
    const response = await supplierTenants.items_csv_attachments.update(
      attachmentId,
      data
    )

    commit('SET_CSV_JOB_DATA', response.data)
  },
  async updateDraftItem({ dispatch, commit }, { id, params }) {
    try {
      commit('SET_CSV_IMPORT_LOADING', true)
      const response =
        await supplierTenants.items_csv_attachments.draft_items.update(
          id,
          params
        )

      await dispatch('getAttachment', response.data.items_csv_attachment_id)
      commit('SET_CSV_IMPORT_LOADING', false)
    } catch (_) {
      commit('SET_CSV_IMPORT_LOADING', false)
    }
  },
  async updateSupplierTenant(_ctx, { id, ...params }) {
    await supplierTenants.update(id, params)
  },
  async deleteAddress({ commit }, { supplier_tenant_id, ...params }) {
    const response = await supplierTenants.addresses.delete(
      supplier_tenant_id,
      params
    )

    commit('REMOVE_SUPPLIER_TENANT_ADDRESSES', response.data)
  },
  async deleteContact({ commit }, { supplier_tenant_id, ...params }) {
    const response = await supplierTenants.contacts.delete(
      supplier_tenant_id,
      params
    )

    commit('REMOVE_SUPPLIER_TENANT_CONTACTS', response.data)
  },
  async updateSupplierTenantAddresses(
    { commit },
    { supplier_tenant_id, address }
  ) {
    const params = {
      ...address,
      country: address.country.iso,
      state: address.state ? address.state.abbr : null,
    }
    const response = await supplierTenants.addresses.create(
      supplier_tenant_id,
      params
    )

    commit('ADD_SUPPLIER_TENANT_ADDRESSES', response.data)
  },
  async updateSupplierTenantContacts(
    { commit },
    { supplier_tenant_id, contact }
  ) {
    const response = await supplierTenants.contacts.create(
      supplier_tenant_id,
      contact
    )

    commit('ADD_SUPPLIER_TENANT_CONTACTS', response.data)
  },
  async updateAddress({ commit }, { supplier_tenant_id, address }) {
    const params = {
      ...address,
      country: address.country.iso,
      state: address.state ? address.state.abbr : null,
    }
    const response = await supplierTenants.addresses.update(
      supplier_tenant_id,
      params
    )

    commit('ADD_SUPPLIER_TENANT_ADDRESSES', response.data)
  },
  async updateContact({ commit }, { supplier_tenant_id, contact }) {
    const response = await supplierTenants.contacts.update(
      supplier_tenant_id,
      contact
    )

    commit('ADD_SUPPLIER_TENANT_CONTACTS', response.data)
  },
  async getDeliveryDays(_ctx, supplierId) {
    const response = await supplierTenants.delivery_days.index(supplierId)

    return response
  },
  async shorts(_ctx, params) {
    const response = await supplierTenants.shorts.index(params)

    return response
  },
  async createIntegrationSync(_ctx, { supplierTenantId }) {
    const response = await supplierTenants.integration_syncs.create({
      supplierTenantId,
    })

    return response.data
  },
  async resetBaseUnits(_ctx, supplierTenantId) {
    const response = await supplierTenants.reset_base_units.create(
      supplierTenantId
    )
    return response.data
  },
}

export const getters = {
  groupedHolidays(state) {
    return groupBy(state.holidays_of_the_year, (holiday) => {
      const date = dayjs(holiday.date, 'YYYY-MM-DD')

      return `${date.format('MM')}:${date
        .startOf('month')
        .format('DD.MM.YYYY')} - ${date.endOf('month').format('DD.MM.YYYY')}`
    })
  },
  getSupplierTenantAddresses(state) {
    return state.selected_supplier_tenant &&
      state.selected_supplier_tenant.addresses
      ? state.selected_supplier_tenant.addresses
      : []
  },
  getSupplierTenantContacts(state) {
    return state.selected_supplier_tenant &&
      state.selected_supplier_tenant.contacts
      ? state.selected_supplier_tenant.contacts
      : []
  },
  invalidImportItems: (state) => {
    return state.csv.draftItems.filter((item) => !isEmpty(item.errors_data))
  },
  importItemsWithWarnings: (state) => {
    return state.csv.draftItems.filter((item) => !isEmpty(item.warnings_data))
  },
  manuallyFixedItems: (state) => {
    return state.csv.draftItems.filter((item) => item.isManuallyFixed)
  },
  validImportItems: (state) => {
    return state.csv.draftItems.filter(
      (item) => isEmpty(item.errors_data) || item.isManuallyFixed
    )
  },
  overwrittenItems: (state) => {
    return state.csv.draftItems.filter(
      (item) =>
        (isEmpty(item.errors_data) || item.isManuallyFixed) && item.overwrite
    )
  },
  isDeliveryFormInvalid: (state) => {
    return (
      !state.delivery_form.delivery_cost_center.value ||
      !state.delivery_form.customer_number ||
      !state.delivery_form.supplier_number
    )
  },
  showAllSuppliers: (_state, _getters, rootState) => {
    return rootState.currentUser.currentUser.show_all_suppliers
  },
}
