import settingsApi from '@/services/api/settings'

export const namespaced = true

export const state = {
  list: [],
  segment_taxons_list: [],
  count: 0,
  total_count: 0,
  current_page: 0,
  pages: 0,
  per_page: 0,
  infiniteId: +new Date(),
  q: null,
}

export const mutations = {
  SET_PRODUCT_GROUPS_DATA(state, { product_groups, total_count }) {
    state.list = [...state.list, ...product_groups]
    state.current_page += 1
    state.total_count = total_count
  },
  SET_SEGMENTS_DATA(state, { segment_taxons }) {
    state.segment_taxons_list = segment_taxons
  },
  CLEAR_PRODUCT_GROUPS(state) {
    state.list = []
    state.current_page = 0
    state.infiniteId += 1
  },
}

export const actions = {
  async clearProductGroups({ commit }) {
    commit('CLEAR_PRODUCT_GROUPS')
  },
  async getProductGroups({ commit, state }, { infiniteLoaderContext, code }) {
    try {
      const response = await settingsApi.product_groups.show(
        {
          page: state.current_page + 1,
          q: state.q,
        },
        code
      )

      if (response.data.pages <= response.data.current_page) {
        infiniteLoaderContext.complete()
      } else {
        infiniteLoaderContext.loaded()
      }

      commit('SET_PRODUCT_GROUPS_DATA', response.data)
    } catch (error) {
      infiniteLoaderContext.error()
    }
  },
  async getSegmentTaxons({ commit }) {
    const response = await settingsApi.product_groups.index()

    commit('SET_SEGMENTS_DATA', response.data)
  },
}
