import NotFound from '@/views/not-found.vue'
import NProgress from 'nprogress'
import TokenStorage from '@/services/token-storage'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/login.vue'),
    meta: { template: 'empty' },
  },
  {
    path: '/cart',
    name: 'cart',
    meta: { protected: true },
    component: () => import('@/views/cart.vue'),
  },
  {
    path: '/cart-templates',
    name: 'cart-templates',
    meta: { protected: true },
    component: () => import('@/views/order-templates.vue'),
  },
  {
    path: '/cart-templates/:id',
    name: 'cart-template',
    meta: { protected: true, subPathOf: '/cart-templates' },
    component: () => import('@/views/order-template.vue'),
  },
  {
    path: '/companies',
    name: 'companies',
    meta: { protected: true, template: 'main', subPathOf: '/more' },
    component: () => import('@/views/companies.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: { protected: true },
    component: () => import('@/views/dashboard.vue'),
  },
  {
    path: '/dashboard/total-orders',
    name: 'total-orders',
    meta: { protected: true, subPathOf: '/dashboard' },
    component: () => import('@/views/dashboard/total-orders.vue'),
  },
  {
    path: '/deliveries',
    name: 'deliveries',
    meta: { protected: true },
    component: () => import('@/views/deliveries.vue'),
  },
  {
    path: '/deliveries/:number',
    name: 'delivery',
    meta: { protected: true, subPathOf: '/orders' },
    component: () => import('@/views/delivery.vue'),
  },
  {
    path: '/expiring-prices',
    name: 'expiring-prices',
    meta: { protected: true },
    component: () => import('@/views/expiring-prices.vue'),
  },
  {
    path: '/items',
    name: 'items',
    meta: { protected: true },
    component: () => import('@/views/items.vue'),
  },
  {
    path: '/replacement-groups',
    name: 'replacement-groups',
    meta: { protected: true, subPathOf: '/more' },
    component: () => import('@/views/replacement-groups.vue'),
  },
  {
    path: '/items/:id',
    name: 'item',
    meta: { protected: true, subPathOf: '/items' },
    component: () => import('@/views/item.vue'),
  },
  {
    path: '/invitations/:token',
    name: 'invitations',
    meta: { template: 'empty' },
    component: () => import('@/views/invitations.vue'),
  },
  {
    path: '/members',
    name: 'users',
    alias: '/users',
    meta: { protected: true, template: 'main', subPathOf: '/more' },
    component: () => import('@/views/users.vue'),
  },
  {
    path: '/internal_users',
    name: 'internal-users',
    meta: { protected: true, template: 'main', subPathOf: '/more' },
    component: () => import('@/views/internal-users.vue'),
  },
  {
    path: '/open-payments',
    name: 'open-payments',
    meta: { protected: true },
    component: () => import('@/views/open-payments.vue'),
  },
  {
    path: '/more',
    name: 'more',
    meta: { protected: true, template: 'main' },
    component: () => import('@/views/pages.vue'),
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: NotFound,
    meta: { template: 'empty' },
  },
  {
    path: '/orders',
    name: 'orders',
    meta: { protected: true },
    component: () => import('@/views/master-orders.vue'),
  },
  {
    path: '/master-orders/:number',
    name: 'master-order',
    meta: { protected: true, subPathOf: '/orders' },
    component: () => import('@/views/master-order.vue'),
  },
  {
    path: '/orders/:number',
    name: 'order',
    meta: { protected: true, subPathOf: '/orders' },
    component: () => import('@/views/order.vue'),
  },
  {
    path: '/procurements',
    name: 'procurements',
    meta: { protected: true, subPathOf: '/dashboard' },
    component: () => import('@/views/procurements.vue'),
  },
  {
    path: '/settings/product-groups',
    name: 'product-groups',
    meta: { protected: true, subPathOf: '/more' },
    component: () => import('@/views/settings/product-groups.vue'),
  },
  {
    path: '/settings/product-groups/:code',
    name: 'product-group',
    meta: { protected: true, subPathOf: '/more' },
    component: () => import('@/views/settings/product-group.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    meta: { protected: true, subPathOf: '/more' },
    component: () => import('@/views/profile.vue'),
  },
  {
    path: '/restore-password',
    name: 'restore-password',
    component: () => import('@/views/restore-password.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    meta: { protected: true, template: 'main', subPathOf: '/more' },
    component: () => import('@/views/settings.vue'),
  },
  {
    path: '/import-history',
    name: 'import-history',
    meta: { protected: true, subPathOf: '/more' },
    component: () => import('@/views/import-history.vue'),
  },
  {
    path: '/settings/additives',
    name: 'additives',
    meta: { protected: true, subPathOf: '/more' },
    component: () => import('@/views/settings/additives.vue'),
  },
  {
    path: '/settings/allergens',
    name: 'allergens',
    meta: { protected: true, subPathOf: '/more' },
    component: () => import('@/views/settings/allergens.vue'),
  },
  {
    path: '/settings/base-unit-types',
    name: 'base-unit-types',
    meta: { protected: true, subPathOf: '/more' },
    component: () => import('@/views/settings/base-unit-types.vue'),
  },
  {
    path: '/settings/units',
    name: 'units',
    meta: { protected: true, subPathOf: '/more' },
    component: () => import('@/views/settings/units.vue'),
  },
  {
    path: '/settings/packaging-types',
    name: 'packaging-types',
    meta: { protected: true, subPathOf: '/more' },

    component: () => import('@/views/settings/packaging-types.vue'),
  },
  {
    path: '/settings/tax-rates',
    name: 'tax-rates',
    meta: { protected: true, subPathOf: '/more' },
    component: () => import('@/views/settings/tax-rates.vue'),
  },
  {
    path: '/suppliers',
    name: 'suppliers',
    meta: { protected: true, subPathOf: '/more' },
    component: () => import('@/views/suppliers.vue'),
  },
  {
    path: '/supplier-tenants',
    name: 'supplier-tenants',
    meta: { protected: true, subPathOf: '/more' },
    component: () => import('@/views/supplier-tenants.vue'),
  },
  {
    path: '/users/password/:token',
    name: 'user-password',
    component: () => import('@/views/password.vue'),
  },
  {
    path: '/accounts',
    name: 'accounts',
    meta: { protected: true },
    component: () => import('@/views/accounts.vue'),
  },
  {
    path: '*',
    redirect: { name: 'not-found' },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, _from, next) => {
  const isLoggedIn = TokenStorage.getAccessToken()

  if (to.matched.some((record) => record.meta.protected) && !isLoggedIn) {
    next('/')
  }

  next()
})

router.beforeResolve((to, from, next) => {
  if (to.path) {
    NProgress.start()
  }
  next()
})

router.afterEach((to) => {
  NProgress.done()

  Vue.nextTick(() => {
    router.app.$posthog.capture('$pageview', {
      $current_url: to.fullPath,
    })
  })
})

export default router
