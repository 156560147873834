import apiClient from '@/services/api'

export default {
  index(params) {
    return apiClient.get('api/procurements', { params })
  },

  pdf: {
    index(params) {
      return apiClient.get('api/procurements.pdf', {
        params,
        responseType: 'blob',
      })
    },
  },
}
