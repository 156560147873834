import itemsApi from '@/services/api/items'
import replacementGroupsApi from '@/services/api/replacement-groups'
import { isEmpty } from 'lodash'

export const namespaced = true

export const state = {
  count: 0,
  total_count: 0,
  current_page: 0,
  global_count: null,
  loading: false,
  pages: 0,
  per_page: 0,
  q: null,
  list: [],
  item: {},
  sort: {
    value: { name: 'title', attr: 'replacement_groups.name' },
    options: [
      { name: 'name_asc', attr: 'replacement_groups.name' },
      { name: 'name_desc', attr: '-replacement_groups.name' },
    ],
  },
  infiniteId: +new Date(),
  isBlankState: null,
}

export const mutations = {
  ADD_REPLACEMENT_GROUPS(
    state,
    { data: { replacement_groups, ...paginationMeta }, noFilters }
  ) {
    state.list = [...state.list, ...replacement_groups]
    state.current_page += 1
    state.total_count = paginationMeta.total_count
    state.isBlankState = noFilters && paginationMeta.total_count === 0
  },
  ADD_REPLACEMENT_GROUP(state, { replacement_group }) {
    state.list.push(replacement_group)
    state.total_count += 1
    state.isBlankState = false
  },
  REMOVE_REPLACEMENT_GROUP(state, { data: { replacement_group }, noFilters }) {
    state.list = state.list.filter((item) => item.id !== replacement_group.id)
    state.total_count -= 1
    state.isBlankState = noFilters && state.total_count === 0
  },
  RESET_LOADED_REPLACEMENT_GROUPS(state) {
    state.list = []
    state.current_page = 0
    state.infiniteId += 1
  },
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_REPLACEMENT_GROUP_ITEMS(state, { replacement_group }) {
    const { id, items } = replacement_group

    state.list = state.list.map((replacementGroup) => {
      if (replacementGroup.id !== id) return replacementGroup

      return { ...replacementGroup, items }
    })
  },
  SET_REPLACEMENT_GROUP(state, { replacement_group }) {
    state.item = replacement_group
  },
  UPDATE_REPLACEMENT_GROUP(state, { replacement_group }) {
    state.item = { ...replacement_group }
    const updatedList = [...state.list]

    for (let i = 0; i < updatedList.length; i++) {
      if (updatedList[i].id === replacement_group.id) {
        updatedList[i] = { ...replacement_group }
        break
      }
    }

    state.list = updatedList
  },
  UPDATE_REPLACEMENT_GROUP_ITEM(state, { item }) {
    state.item = {
      ...state.item,
      items: state.item.items.map((rg_item) =>
        rg_item.variant_id === item.variant_id
          ? { ...rg_item, ...item }
          : rg_item
      ),
    }

    state.list = state.list.map((replacementGroup) => {
      replacementGroup.items = replacementGroup.items?.map((oldItem) => {
        if (oldItem.variant_id !== item.variant_id) return oldItem

        return { ...oldItem, ...item }
      })

      return replacementGroup
    })
  },
}

export const actions = {
  async createReplacementGroup({ commit }, params) {
    const response = await replacementGroupsApi.create(params)

    commit('ADD_REPLACEMENT_GROUP', response.data)
  },
  async deleteReplacementGroup({ commit, state }, id) {
    const response = await replacementGroupsApi.delete(id)

    commit('REMOVE_REPLACEMENT_GROUP', {
      data: response.data,
      noFilters: isEmpty(state.q),
    })
  },
  async updateReplacementGroup({ commit }, { id, data }) {
    const response = await replacementGroupsApi.update(id, data)
    commit('UPDATE_REPLACEMENT_GROUP', response.data)
  },
  async getReplacementGroups({ commit, state }, infiniteLoaderContext) {
    const {
      current_page,
      q,
      sort: { value },
    } = state
    const startInfiniteId = state.infiniteId

    try {
      const response = await replacementGroupsApi.index({
        page: current_page + 1,
        q,
        sort: value && value.attr,
      })

      const endInfiniteId = state.infiniteId

      if (startInfiniteId !== endInfiniteId) return

      if (response.data.pages <= current_page) {
        infiniteLoaderContext.complete()
      } else {
        infiniteLoaderContext.loaded()
      }
      commit('ADD_REPLACEMENT_GROUPS', {
        data: response.data,
        noFilters: isEmpty(q),
      })
    } catch (error) {
      infiniteLoaderContext.error()
    }
  },
  async getReplacementGroup({ commit }, id) {
    const response = await replacementGroupsApi.show(id)

    commit('SET_REPLACEMENT_GROUP_ITEMS', response.data)
    commit('SET_REPLACEMENT_GROUP', response.data)

    return response.data.replacement_group
  },
  filterReplacementGroups({ commit }) {
    commit('RESET_LOADED_REPLACEMENT_GROUPS')
  },
  async verifyUnits({ commit }, { replacementGroupId }) {
    const response = await replacementGroupsApi.verify_units.create(
      replacementGroupId
    )

    commit('SET_REPLACEMENT_GROUP_ITEMS', response.data)
    commit('SET_REPLACEMENT_GROUP', response.data)

    return response.data.replacement_group
  },
  async verifyItemUnits({ commit }, { variant_id }) {
    const response = await itemsApi.verify_units.create(variant_id)

    commit('UPDATE_REPLACEMENT_GROUP_ITEM', response.data)

    return response.data
  },
  updateReplacementGroupItem({ commit }, item) {
    commit('UPDATE_REPLACEMENT_GROUP_ITEM', { item })
  },
}
