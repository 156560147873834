import procurementsApi from '@/services/api/procurements'
import supplierTenantsApi from '@/services/api/supplier-tenants'
import { sortBy, uniqBy } from 'lodash'

export const namespaced = true

export const state = {
  count: 0,
  total_count: 0,
  current_page: 0,
  pages: 0,
  per_page: 0,
  list: [],
  widget_data: {
    total: '0.0',
    total_delta: '0.0',
    total_percent_delta: '0.0',
    organic_percent: '0.0',
    organic_percent_delta: null,
    regional_percent: '0.0',
    regional_percent_delta: null,
    organic_and_regional_percent: '0.0',
    organic_and_regional_percent_delta: null,
    other_and_shipping_percent: '0.0',
    other_and_shipping_percent_delta: null,
    top_suppliers: [],
    suppliers: [],
  },
  period: {
    investigated: {
      locale_code: 'procurements.investigated',
      start_at: null,
      end_at: null,
      type: 'dateRange',
    },
    compared: {
      locale_code: 'procurements.compared',
      start_at: null,
      end_at: null,
      type: 'dateRange',
    },
  },
  filters: {
    suppliers: {
      value: [],
      loading: false,
      selected: false,
      options: [],
      type: 'checkbox',
      withAsyncOptions: true,
    },
  },
  infiniteId: +new Date(),
}

export const mutations = {
  ADD_SUPPLIER_TENANTS(state, { supplier_tenants, ...meta }) {
    state.list = uniqBy([...state.list, ...supplier_tenants], 'name')
    state.current_page += 1
    state.total_count = meta.total_count
    state.widget_data = meta.widget_data
    state.period.investigated.start_at = meta.widget_data.investigated_start_at
    state.period.investigated.end_at = meta.widget_data.investigated_end_at
    state.period.compared.start_at = meta.widget_data.compared_start_at
    state.period.compared.end_at = meta.widget_data.compared_end_at
  },
  APPLY_COMPARED_PERIOD(state, { start, end }) {
    state.period.compared.start_at = start
    state.period.compared.end_at = end
  },
  APPLY_FILTER(state, { type, filterItem }) {
    if (
      filterItem.id &&
      state.filters[type].value.some((itm) => itm.id === filterItem.id)
    ) {
      state.filters[type].value = state.filters[type].value.filter(
        (existingItem) => {
          return existingItem.id !== filterItem.id
        }
      )
    } else {
      state.filters[type].value.push(filterItem)
    }
  },
  APPLY_INVESTIGATED_PERIOD(state, { start, end }) {
    state.period.investigated.start_at = start
    state.period.investigated.end_at = end
  },
  CLEAR_RANGE_FILTERS(state) {
    state.period.investigated.start_at = null
    state.period.investigated.end_at = null
    state.period.compared.start_at = null
    state.period.compared.end_at = null
  },
  CLEAR_SUPPLIER_TENANTS(state) {
    state.list = []
    state.current_page = 0
    state.infiniteId += 1
    state.widget_data = {
      organic_percent: '0.0',
      organic_percent_delta: null,
      regional_percent: '0.0',
      regional_percent_delta: null,
      organic_and_regional_percent: '0.0',
      organic_and_regional_percent_delta: null,
      other_and_shipping_percent: '0.0',
      other_and_shipping_percent_delta: null,
      top_suppliers: [],
      suppliers: [],
    }
  },
  TOGGLE_FILTER_ITEM(state, filterItem) {
    state.filters[filterItem.name].selected =
      !state.filters[filterItem.name].selected
  },
  SET_FILTER_LOADING(state, { value, type }) {
    state.filters[type].loading = value
  },
  SET_FILTER_OPTIONS(state, { data, type }) {
    state.filters[type].options = data.map((item) => {
      return {
        id: item.id,
        name: item.name,
        code: item.code,
        type,
      }
    })
  },
}

export const actions = {
  applyComparedPeriod({ commit }, { start, end }) {
    commit('APPLY_COMPARED_PERIOD', { start, end })
    commit('CLEAR_SUPPLIER_TENANTS')
  },
  applyFilter({ commit }, { filterItem, type }) {
    commit('APPLY_FILTER', { filterItem, type })
    commit('CLEAR_SUPPLIER_TENANTS')
  },
  applyInvestigatedPeriod({ commit }, { start, end }) {
    commit('APPLY_INVESTIGATED_PERIOD', { start, end })
    commit('CLEAR_SUPPLIER_TENANTS')
  },
  async getSupplierTenants(
    { commit, state },
    { infiniteLoaderContext, period }
  ) {
    const {
      current_page,
      filters: { suppliers },
      period: { investigated, compared },
    } = state

    try {
      const response = await procurementsApi.index({
        page: current_page + 1,
        period,
        suppliers: suppliers.value.map((item) => item.id).join(','),
        investigated_start_at: investigated.start_at,
        investigated_end_at: investigated.end_at,
        compared_start_at: compared.start_at,
        compared_end_at: compared.end_at,
      })

      if (response.data.pages <= response.data.current_page) {
        infiniteLoaderContext.complete()
      } else {
        infiniteLoaderContext.loaded()
      }

      commit('ADD_SUPPLIER_TENANTS', response.data)
    } catch (_error) {
      infiniteLoaderContext.error()
    }
  },
  clearSupplierTenants({ commit }) {
    commit('CLEAR_SUPPLIER_TENANTS')
    commit('CLEAR_RANGE_FILTERS')
  },
  async loadFilterOptions({ commit }, type) {
    commit('SET_FILTER_LOADING', { value: true, type })

    let options = []

    switch (type) {
      case 'suppliers': {
        const response = await supplierTenantsApi.index({
          all: true,
          is_current_cost_center: true,
        })
        options = response.data.suppliers
        break
      }
    }

    commit('SET_FILTER_OPTIONS', {
      data: sortBy(options, (n) => n.name.toLowerCase(), 'name'),
      type,
    })

    commit('SET_FILTER_LOADING', { value: false, type })
  },
  toggleFilterItem({ commit }, filterItem) {
    commit('TOGGLE_FILTER_ITEM', filterItem)
  },
}

export const getters = {
  filterItems: (state) => {
    return Object.entries(state.filters).flatMap(([key, value]) => {
      return { name: key, ...value }
    })
  },
  periodItems: (state) => {
    return Object.entries(state.period).flatMap(([key, value]) => {
      return { name: key, ...value }
    })
  },
}
