import apiClient from '@/services/api'

export default {
  show() {
    return apiClient.get('api/settings')
  },
  additives: {
    index(params) {
      return apiClient.get('api/settings/additives', { params })
    },
    pdf: {
      index(params) {
        return apiClient.get('api/settings/additives.pdf', { params })
      },
    },
  },
  allergens: {
    index(params) {
      return apiClient.get('api/settings/allergens', { params })
    },
    pdf: {
      index(params) {
        return apiClient.get('api/settings/allergens.pdf', { params })
      },
    },
  },
  base_unit_types: {
    index(params) {
      return apiClient.get('api/settings/base_unit_types', { params })
    },
  },
  units: {
    index(params) {
      return apiClient.get('api/settings/units', { params })
    },
    supplier_unit_mapping: {
      delete(unit_id, id, params) {
        return apiClient.delete(
          `api/settings/units/${unit_id}/supplier_unit_mappings/${id}`,
          { data: params }
        )
      },
    },
  },
  packaging_types: {
    index(params) {
      return apiClient.get('api/settings/packaging_types', { params })
    },
    pdf: {
      index(params) {
        return apiClient.get('api/settings/packaging_types.pdf', { params })
      },
    },
  },
  product_groups: {
    index() {
      return apiClient.get('api/settings/product_groups')
    },
    show(params, code) {
      return apiClient.get(`api/settings/product_groups/${code}`, { params })
    },
    pdf: {
      index(params) {
        return apiClient.get('api/settings/product_groups.pdf', { params })
      },
    },
  },
  tax_rates: {
    index(params) {
      return apiClient.get('api/settings/tax_rates', { params })
    },
    pdf: {
      index(params) {
        return apiClient.get('api/settings/tax_rates.pdf', { params })
      },
    },
  },
}
